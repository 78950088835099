.flexdiv {
	display: flex;
}
.flexbutton {
	margin-top: 0;
	margin-left: 10px;
}
.flexdivTop {
	margin-top: 10px;
}
.flexdivTop p {
	margin-top: 10px;
	font-size: 12px;
	opacity: 0.5;
}
.flexdivTop p b {
	font-weight: bold;
}
.errorDiv {
	color: red;
}
