.arrow {
    position: absolute;
    width: 60px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    line-height: 30px;
    z-index: 1000;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 500ms;
}

.arrow-right {
    right: 0;
}
.arrow-left {
    left: 0;
}
.arrow:hover {
    background-color: rgba(0, 0, 0, 0.11);
    color: #ffeb3b;
}
.item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 0.2s, height 250ms, width 250ms, left 0.2s, margin-top 0.2s, line-height 250ms, background-color 0.2s;
}
.level-hidden{
    visibility: hidden;
}
.level-2 {
    box-shadow: -0.1em 0.1em 0.2em #212121;
    height: 72%;
    line-height: 72%;
    background-color: #228291;
    left: 15%;
}
.level-1 {
    box-shadow: -0.2em 0.2em 0.4em #212121;
    height: 78%;
    line-height: 78%;
    background-color: #6796e5;
    left: 30%;
}
.level0 {
    box-shadow: 0 0.4em 0.8em #212121;
    height: 85%;
    line-height: 85%;
    background-color: #4ec9e1;
    left: 50%;
}
.level1 {
    box-shadow: 0.2em 0.2em 0.4em #212121;
    height: 78%;
    line-height: 78%;
    background-color: #6796e5;
    left: 70%;
}
.level2 {
    box-shadow: 0.1em 0.1em 0.2em #212121;
    height: 72%;
    line-height: 72%;
    background-color: #228291;
    left: 85%;
}
.level-2, .level2 {
    z-index: 0;
}
.level-1, .level1 {
    z-index: 1;
}
.level0 {
    z-index: 2;
}
.left-enter {
    opacity: 0;
    line-height: 42%;
}
.left-enter.left-enter-active {
    opacity: 1;
    height: 72%;
    line-height: 72%;
    transition: box-shadow 0.2s, left 0.2s, opacity 0.2s;
}
.left-leave {
    opacity: 1;
    height: 72%;
    line-height: 72%;
    z-index: -1;
}
.left-leave.left-leave-active {
    z-index: -1;
    opacity: 0;
    line-height: 120px;
    transition: box-shadow 0.2s, opacity 0.2s;
}
.right-enter {
    opacity: 0;
    line-height: 42%;
}
.right-enter.right-enter-active {
    opacity: 1;
    height: 72%;
    line-height: 72%;
    transition: box-shadow 0.2s, opacity 0.2s;
}
.right-leave {
    height: 72%;
    opacity: 1;
    line-height: 72%;
}
.right-leave.right-leave-active {
    opacity: 0;
    line-height: 42%;
    transition: box-shadow 0.2s, opacity 0.2s ;
}
