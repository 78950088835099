@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

body {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    min-width: 700px;
}
button {
    white-space: nowrap;
}
.react-notification-root {
    top: 0;
}
.btn-primary {
    background-color: #0275D8;
    border-color: #0275D8
}

.MuiTooltip-popper > div{
    font-size: 12px;
}
.no-focus-form-item > .Mui-focused > fieldset{
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px !important;;
}

.no-focus-form-item > .Mui-focused > div {
    background: transparent !important;
}

.m-fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 300ms, opacity 300ms;
}
.m-fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s linear 0s, opacity 300ms;
}
